import { T } from "../../../Lang/ToLang.util";
export interface IErrorViewProps {
    error: any;
}

const ErrorView: React.FC<IErrorViewProps> = (props) => {
    return (
        <div className="no-id">
            <div className="no-id__onemin">
                <img className="no-id__onemin__img" alt={"Error Logo"} src={`/logoColored.png`} />
            </div>
            <div className="no-id__text-box">
                <p className="no-id__text-box__error-text">{T("Hiba! Helytelen id.")}</p>
                {props.error?.response?.data?.code && (
                    <p className="no-id__text-box__error-text">
                        {T(props.error?.response?.data?.code)}
                    </p>
                )}

                <p className="no-id__text-box__instruction-text">
                    {T("Kérlek ird be az adminon található helyes URL-t")}
                </p>
            </div>
        </div>
    );
};

export default ErrorView;
