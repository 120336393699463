import React from "react";
import ReactDOM from "react-dom";
import { HOST } from "./Static/LS.static";
import { Switch } from "react-router";
import { Config } from "./Config/Packages.config";
import { RootContextProvider } from "./Contexts/Root.context";
import { BrowserRouter, Route } from "react-router-dom";
import { axios, IDynamicObject } from "xa-generics";
import DefaultAxios, { AxiosError, AxiosResponse } from "axios";
import Environment from "./Models/Environment.model";
import NoID from "./Components/NoID/View/NoID.view";
import TV from "./Components/TV/Controller/Tv.controller";
import "./Style/Fonts.css";
import "./Index.css";

DefaultAxios.get("/api/rest")
    .then((res: AxiosResponse<IDynamicObject>) => new Environment(res.data))
    .then((ENV_KEYS: Environment) => {
        const host = localStorage.getItem(HOST);
        axios.initAxios(host || ENV_KEYS.REST_API_URL, undefined, "hu");
        axios.initAxios(ENV_KEYS.REST_API_URL, "cloud", "hu");
        Config();

        const AppRoot = (
            <React.StrictMode>
                <BrowserRouter>
                    <RootContextProvider>
                        <Switch>
                            <Route exact path="/">
                                <NoID />
                            </Route>
                            <Route exact path="/:id">
                                <TV />
                            </Route>
                        </Switch>
                    </RootContextProvider>
                </BrowserRouter>
            </React.StrictMode>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Maybe copy the error message below to help us solve the issue:</p>
                <p>{error.message}</p>
                {console.error(error)}
            </div>,
            document.getElementById("root")
        );
    });
