import { AxiosPromise, AxiosResponse } from "axios";
import { axios, IDynamicObject } from "xa-generics";
import { HOST, REST_ID } from "../../../Static/LS.static";
import { HostModel } from "../Model/Host.model";

export abstract class URLConfigDAO {
    private static readonly base: string = "/api/v3/restaurants";

    public static async loadServer(id: string): Promise<HostModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance("cloud")
            .get(`${this.base}/${id}/server`);
        const response: AxiosResponse<IDynamicObject> = await request;
        const model = new HostModel(response.data);
        localStorage.setItem(HOST, model.host);
        localStorage.setItem(REST_ID, id);
        axios.getInstance().defaults.baseURL = model.host;
        return model;
    }
}
