import { TvDAO } from "../DAO/Tv.dao";
import { clone } from "lodash";
import { TvItem } from "../Model/TvItem.model";
import { TvModel } from "../Model/Tv.model";
import { useRoot } from "../../../Contexts/Root.context";
import { useState } from "react";
import { TvDataModel } from "../Model/TvData.model";
import { URLConfigDAO } from "../../URLConfig/DAO/URLConfig.dao";
import { RouteComponentProps, withRouter } from "react-router";
import {
    IError,
    ILoading,
    useDidMount,
    Interval,
    useIntervalCleaner,
    Timeout,
    useTimeoutCleaner
} from "xa-generics";
import ErrorView from "../../Error/View/Error.view";
import TvView from "../View/Tv.view";

export interface ITVProps extends RouteComponentProps<{ id: string }> {}

const TV: React.FC<ITVProps> = (props) => {
    const { audio } = useRoot();
    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [list, setList] = useState<TvModel>(new TvModel());
    const [tv, setTv] = useState<TvDataModel>(new TvDataModel());
    const [newDoneItems, setNewDoneItems] = useState<string[]>([]);

    const id = props.match.params.id;

    const diff = (prevArray: TvItem[], newArray: TvItem[]): string[] => {
        let res: string[] = [];

        let prevArrayStrings: string[] = [];
        for (let i = 0; i < prevArray.length; i++) prevArrayStrings.push(prevArray[i].value);

        let newArrayStrings: string[] = [];
        for (let i = 0; i < newArray.length; i++) newArrayStrings.push(newArray[i].value);

        for (let i = 0; i < newArray.length; i++) {
            if (prevArrayStrings.indexOf(newArrayStrings[i]) === -1) res.push(newArrayStrings[i]);
        }

        return res.reverse();
    };

    const showNewDoneItems = (newItems: string[]): void => {
        if (newItems.length === 0) return;
        setNewDoneItems(newItems);
        if (tv.enable_sound) {
            if (!audio.paused) audio.pause();
            if (audio.HAVE_CURRENT_DATA) {
                audio.muted = false;
                audio.autoplay = true;
                audio.play();
            }
        }

        Timeout.bindTimeout(() => setNewDoneItems([]), 3000, "showNewDoneItems");
    };

    const onLoad = (): void => {
        setLoading(
            TvDAO.load(id)
                .then((tvList) => {
                    setList((current) => {
                        const doneDiff = diff(list.done, tvList.done);
                        showNewDoneItems(doneDiff);
                        const state = clone(current);
                        state.in_progress = tvList.in_progress;

                        for (let i = 0; i < doneDiff.length; i++)
                            state.done.unshift(new TvItem({ value: doneDiff[i] }));

                        state.done.splice(20);

                        return state;
                    });
                })
                .catch((error: IError) => {
                    if (
                        (error as any)?.response?.data?.code === "HOST_NOT_ACCESSIBLE_NO_CONNECTION"
                    )
                        Interval.clearAllIntervals();
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    const getTV = (): void => {
        setLoading(
            TvDAO.loadTV(id)
                .then((tv) => {
                    setTv(tv);
                    URLConfigDAO.loadServer(tv.restaurant_id)
                        .then((data) => {
                            onLoad();
                            Interval.bindInterval(onLoad, 15000, "get_tv_datas");
                        })
                        .catch((e) => console.error(e));
                })
                .catch((error: any) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useDidMount(getTV);

    useIntervalCleaner();
    useTimeoutCleaner();

    if (error) return <ErrorView error={error} />;

    if (newDoneItems.length > 0)
        return (
            <div className="new-done-orders">
                <div className="top-container">
                    <div className="top-container__text">ELKÉSZÜLT</div>
                </div>
                {newDoneItems.map((item) => (
                    <div key={`${item}-new-done-item`} className="text">
                        {item}
                    </div>
                ))}
            </div>
        );

    return <TvView list={list} loading={loading} tv={tv} />;
};

export default withRouter(TV);
