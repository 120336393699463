import { Context, createContext, useState, useContext } from "react";

export interface IRootContext {
    audio: HTMLAudioElement;
}

/**
 * ## RootContext
 */
const RootContext: Context<IRootContext> = createContext<IRootContext>(null as any);

RootContext.displayName = "RootContext";

interface IRootContextProviderProps {}

/**
 * ## Root context provider component
 *
 */
export const RootContextProvider: React.FC<IRootContextProviderProps> = (props) => {
    const [audio] = useState<HTMLAudioElement>(new Audio("/Assets/order.mp3"));

    return (
        <RootContext.Provider
            value={{
                audio
            }}
        >
            {props.children}
        </RootContext.Provider>
    );
};

export const useRoot = (): IRootContext => useContext(RootContext);
