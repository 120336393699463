import { ModelConstructor, IDynamicObject } from "xa-generics";
import { TvItem } from "./TvItem.model";

export class TvModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TvMData;
        ModelConstructor(data, this);

        if (data.in_progress instanceof Array) {
            this.in_progress = data.in_progress.map((item) => new TvItem({ value: item }));
        }

        if (data.done instanceof Array) {
            this.done = data.done.map((item) => new TvItem({ value: item }));
        }
    }

    in_progress: TvItem[] = [];
    done: TvItem[] = [];
}

//If you add public methods, you may want to replace the empty string with them!
export type TvMData = Partial<InstanceType<typeof TvModel>>;
