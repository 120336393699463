import { ILoading } from "xa-generics";
import { TvModel } from "../Model/Tv.model";
import { TvDataModel } from "../Model/TvData.model";
import TvElementView from "./Tv.element.view";
import FancyLoading from "../../UI/Loading/FancyLoading.view";

export interface ITvViewProps {
    list: TvModel;
    loading: ILoading;
    tv: TvDataModel;
}

const TvView: React.FC<ITvViewProps> = (props) => {
    if (props.loading && !props.tv.id) return <FancyLoading />;

    return (
        <div>
            <div className="tv__onemin">
                <img className="tv__onemin__img" alt="Tv Logo" src={`/logoColored.png`} />
            </div>

            <div
                className="tv"
                style={{
                    backgroundColor: props.tv.background_color
                        ? props.tv.background_color
                        : "white",
                    backgroundImage:
                        props.tv.background !== "" ? `url("${props.tv.background}")` : ""
                }}
            >
                <div className="tv__container">
                    <div className="tv__container__title-container tv__container__title-container--in-progress">
                        <div className="tv__container__title">FOLYAMATBAN</div>
                    </div>
                    <div className="tv__element-container ">
                        {props.list.in_progress.slice(0, 8).map((item, index) => {
                            return <TvElementView item={item} key={`${index}-article`} />;
                        })}
                    </div>
                </div>
                <div className="tv__container tv__container--no-border">
                    <div className="tv__container__title-container tv__container__title-container--done">
                        <div className="tv__container__title">KÉSZ</div>
                    </div>
                    <div className="tv__element-container ">
                        {props.list.done.slice(0, 8).map((item, index) => {
                            return <TvElementView item={item} key={`${index}-article`} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TvView;
