import { axios } from "xa-generics";
import { URLConfigDAO } from "../Components/URLConfig/DAO/URLConfig.dao";
import { HOST, REST_ID } from "../Static/LS.static";

export const Config = (): void => {
    axios.getInstance().interceptors.response.use(
        (value) => value,
        (error) =>
            new Promise((res, rej) => {
                if (error && error.message === "Network Error") {
                    let currentURL = axios.getInstance().defaults.baseURL;
                    const rid = localStorage.getItem(REST_ID);

                    URLConfigDAO.loadServer(rid || "").then((model) => {
                        if (model.host !== currentURL) {
                            axios.getInstance().defaults.baseURL = model.host;
                            localStorage.setItem(HOST, model.host);
                            window.location.reload();
                            return rej(error);
                        } else {
                            error = {
                                response: {
                                    data: {
                                        code: "HOST_NOT_ACCESSIBLE_NO_CONNECTION"
                                    }
                                }
                            };
                            return rej(error);
                        }
                    });
                } else rej(error);
            })
    );
};
