import { AxiosPromise, AxiosResponse } from "axios";
import { axios, IDynamicObject } from "xa-generics";
import { TvDataModel } from "../Model/TvData.model";
import { TvModel } from "../Model/Tv.model";

export abstract class TvDAO {
    private static readonly base: string = "/api/v3/restaurant-tvs";

    public static async loadTV(key: string): Promise<TvDataModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance("cloud")
            .get(`${this.base}/${key}`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new TvDataModel(response.data);
    }

    public static async load(key: string): Promise<TvModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance()
            .get(`${this.base}/${key}/orders`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new TvModel(response.data);
    }
}
