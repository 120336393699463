import { ModelConstructor, IDynamicObject } from "xa-generics";

export class TvDataModel {
    constructor(data?: IDynamicObject<any>, tv_url?: string) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    id: string = "";
    name: string = "";
    archived_at: string = "";
    created_at: string = "";
    updated_at: string = "";
    restaurant_id: string = "";
    background_color: string = "";
    api_key: string = "";
    background: string = "";
    enable_sound: boolean = true;

    sit_in_order: boolean = false;
    take_away_order: boolean = false;
    online_order: boolean = false;
    phone_order: boolean = false;
}
