import { ModelConstructor, IDynamicObject, uuidv4 } from "xa-generics";

export class TvItem {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TvMData;
        ModelConstructor(data, this);
    }

    id: string = uuidv4();
    value: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
export type TvMData = Partial<InstanceType<typeof TvItem>>;
