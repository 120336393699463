export interface IFancyLoadingProps {}

const FancyLoading: React.FC<IFancyLoadingProps> = () => {
    return (
        <div className="fancy-loader-container">
            <div className="loader"></div>
            Loading...
        </div>
    );
};

export default FancyLoading;
