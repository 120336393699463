import { TvItem } from "../Model/TvItem.model";

export interface ITvElementViewProps {
    item: TvItem;
}

const TvElementView: React.FC<ITvElementViewProps> = (props) => {
    return (
        <div className="tv__element" key={props.item.id}>
            <div className="tv__element__text">{props.item.value}</div>
        </div>
    );
};

export default TvElementView;
