import { T } from "../../../Lang/ToLang.util";

export interface INoIDViewProps {}

const NoIDView: React.FC<INoIDViewProps> = (props) => {
    return (
        <div className="no-id">
            <div className="no-id__onemin">
                <img className="no-id__onemin__img" alt="No ID Logo" src={`/logoColored.png`} />
            </div>
            <div className="no-id__text-box">
                <p className="no-id__text-box__error-text">{T("Hiba! Hiányzó id.")}</p>
                <p className="no-id__text-box__link-text">
                    {T("queue.onemin.hu/")}
                    <span className="no-id__text-box__link-text--red">{T("id")}</span>
                </p>
                <p className="no-id__text-box__instruction-text">
                    {T("Kérlek ird be az adminon található teljes URL-t")}
                </p>
            </div>
        </div>
    );
};

export default NoIDView;
