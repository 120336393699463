import { ModelConstructor, IDynamicObject } from "xa-generics";

export class HostModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as HostMData;
        ModelConstructor(data, this);

        if (this.host.endsWith("/")) {
            this.host = this.host.substring(0, this.host.length - 1);
        }
    }
    host: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
export type HostMData = Partial<InstanceType<typeof HostModel>>;
